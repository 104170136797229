<template>
  <div class="max-form" :style="isGreenn ? 'left: 50px !important;' : ''">
    <div class="header">
      <ul class="breadcrumb-form" style="margin: 0">
        <router-link v-if="navigation" to="/dynamicRoute/home"
          >Dashboard</router-link
        >
        <li v-for="(item, index) in navigation" :key="index">
          <img src="@/assets/img/icons/arrowBread.svg" alt="arrow" />
          <router-link class="active" :to="item.to">{{
            item.link
          }}</router-link>
        </li>
      </ul>
      <div class="sequence">
        <p @click="step = 0" :class="step === 0 ? 'active' : ''">Criação</p>
        <p @click="checkInputs()" :class="step === 1 ? 'active' : ''">
          Configurações
        </p>
        <!-- <p v-if="step < 1">Finalizar</p> -->
        <BaseButton
          style="min-width: 135px;"
          :disabled="loading"
          @click="step === 1 ? saveData() : checkInputs()"
          variant="primary"
        >
          {{ step == 1 ? "Salvar" : "Continuar" }}
        </BaseButton>
      </div>
    </div>
    <div :class="{'step-slider': !isGreenn, 'step-slider2': isGreenn}">
      <div class="drag-body" :style="stepStyles">
        <div class="inputs" style="overflow: auto">
          <h3>Tipos de dados</h3>
          <draggable
            class="dragArea list-group"
            :list="validateCamps"
            :group="{ name: 'people', pull: 'clone', put: false }"
            :clone="cloneInput"
            @change="log"
          >
            <div v-for="(item, index) in validateCamps" :key="index" class="input">
              <img :src="require(`@/assets/forms/${item.icon}.svg`)" alt="icon" />
              <p>{{ item.name }}</p>
            </div>
          </draggable>
        </div>
        <div class="form-area">
          <h3>Este é o seu formulário</h3>
          <p>Você pode editá-lo arrastando os componentes do menu lateral</p>
          <div class="form-maker">
            <div class="header-form">
              <div class="ball"></div>
              <div class="ball" style="background: #00972a"></div>
              <div class="ball" style="background: var(--orange)"></div>
            </div>
  
            <div class="border-form">
              <div id="make_form">
                <div v-for="n in 3" v-if="loadingForm">
                  <div style="height: 21.3px; width: 40%; margin-bottom: 7px" class="shine"></div>
                  <div style="height: 45px; width: 100%; margin-bottom: 12px" class="shine"></div>
                </div>
                <draggable
                  class="dragArea list-group"
                  :list="forms"
                  group="people"
                  @change="log"
                  v-show="!loadingForm"
                >
                  <div
                    class="inputs-list"
                    v-for="(form, index) in forms"
                    :key="index"
                  >
                    <div class="actions">
                      <button @click="removeItem(index)">
                        <svg
                          width="14"
                          height="10"
                          viewBox="0 0 24 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.4099 8L19.7099 1.71C19.8982 1.5217 20.004 1.2663 20.004 1C20.004 0.733701 19.8982 0.478306 19.7099 0.290002C19.5216 0.101699 19.2662 -0.00408936 18.9999 -0.00408936C18.7336 -0.00408936 18.4782 0.101699 18.2899 0.290002L11.9999 6.59L5.70994 0.290002C5.52164 0.101699 5.26624 -0.00408912 4.99994 -0.00408912C4.73364 -0.00408912 4.47824 0.101699 4.28994 0.290002C4.10164 0.478306 3.99585 0.733701 3.99585 1C3.99585 1.2663 4.10164 1.5217 4.28994 1.71L10.5899 8L4.28994 14.29C4.19621 14.383 4.12182 14.4936 4.07105 14.6154C4.02028 14.7373 3.99414 14.868 3.99414 15C3.99414 15.132 4.02028 15.2627 4.07105 15.3846C4.12182 15.5064 4.19621 15.617 4.28994 15.71C4.3829 15.8037 4.4935 15.8781 4.61536 15.9289C4.73722 15.9797 4.86793 16.0058 4.99994 16.0058C5.13195 16.0058 5.26266 15.9797 5.38452 15.9289C5.50638 15.8781 5.61698 15.8037 5.70994 15.71L11.9999 9.41L18.2899 15.71C18.3829 15.8037 18.4935 15.8781 18.6154 15.9289C18.7372 15.9797 18.8679 16.0058 18.9999 16.0058C19.132 16.0058 19.2627 15.9797 19.3845 15.9289C19.5064 15.8781 19.617 15.8037 19.7099 15.71C19.8037 15.617 19.8781 15.5064 19.9288 15.3846C19.9796 15.2627 20.0057 15.132 20.0057 15C20.0057 14.868 19.9796 14.7373 19.9288 14.6154C19.8781 14.4936 19.8037 14.383 19.7099 14.29L13.4099 8Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                      <button @click="openModal('input-modal', form, index)">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.5 2.50001C18.8978 2.10219 19.4374 1.87869 20 1.87869C20.5626 1.87869 21.1022 2.10219 21.5 2.50001C21.8978 2.89784 22.1213 3.4374 22.1213 4.00001C22.1213 4.56262 21.8978 5.10219 21.5 5.50001L12 15L8 16L9 12L18.5 2.50001Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <button v-if="validateNames(form)" @click="duplicateItem(form, forms.indexOf(form))">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2 17L12 22L22 17"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2 12L12 17L22 12"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 2L2 7L12 12L22 7L12 2Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div class="mb-3" v-html="form.html"></div>
                  </div>
                </draggable>
                <BaseButton
                  v-b-tooltip.hover
                  title="Botão apenas para visualização. Dentro da edição da página será disponibilizada a edição."
                  variant="primary"
                  class="w-100"
                  :class="{'shine' : loadingForm}"
                >
                  Cadastre-se
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <configForm
          ref="configForm"
          @pegarDados="puxarDados"
          :permission="permission"
          :form_metas="form_metas"
          :isFormLoaded="isFormLoaded"
        />
      </div>
    </div>
    <inputModal @updateHtml="updateHtml" :editInput="editInput" />
  </div>
</template>

<script>
function setMask() {
  setTimeout(() => {
    var itens_telefone = document.querySelectorAll(
      '[mascara="telefone form-control"]'
    );

    for (let i = 0; i < itens_telefone.length; i++) {
      const element = itens_telefone[i];
      if (element) {
        element.addEventListener("keypress", function (e) {
          element.addEventListener("input", function (e) {
          let value = e.target.value;
          value = value.replace(/\D/g, "");
          if (value.length <= 10) {
            value = value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
          } else {
            value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
          }
          value = value.substr(0, 15);
          e.target.value = value;
        });
        });
      }
    }

    var itens_celular = document.querySelectorAll(
      '[mascara="celular form-control"]'
    );

    for (let i = 0; i < itens_celular.length; i++) {
      const element = itens_celular[i];
      if (element) {
        element.addEventListener("keypress", function (e) {
          // var x = e.target.value
          //   .replace(/\D/g, "")
          //   .match(/(\d{2})(\d{4})(\d{4})/);
          // if (e.target.value.length > 8) {
          //   e.target.value = "(" + x[1] + ") " + x[2] + "-" + x[3];
          // }
          e.target.value = e.target.value.replace(/\D/g, "");
          e.target.value = e.target.value.replace(/(\d{2})(\d)/, "($1) $2");
          e.target.value = e.target.value.replace(/(\d)(\d{4})$/, "$1-$2");
          e.target.value = e.target.value.substr(0, 14);
        });
      }
    }

    var itens_cpf = document.querySelectorAll('[mascara="cpf form-control"]');

    for (let i = 0; i < itens_cpf.length; i++) {
      const element = itens_cpf[i];
      if (element) {
        element.addEventListener("keypress", function (e) {
          e.target.value = e.target.value.replace(/\D/g, "");
          e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2");
          e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2");
          //de noe.target.valueo (para o segundo bloco de números)
          e.target.value = e.target.value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
          e.target.value = e.target.value.substr(0, 13);
        });
      }
    }
  }, 1000);
}

import draggable from "vuedraggable";
import configForm from "@/components/Formularios/configForm";
import inputModal from "@/components/Formularios/inputModal";
import Vue from "vue";
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
export default {
  components: {
    draggable,
    configForm,
    inputModal,
  },
  data() {
    return {
      isFormLoaded: false,
      step: 0,
      inputsNames: [
        'nome','email','telefone'
      ],
      loadingForm: false,
      navigation: [
        { link: "Funis", to: this.$route.fullPath.replace(/funis\/.*/, 'funis/') + 'lista/' },
        { link: "Funil atual", to: this.$route.fullPath.replace(/funis\/.*/, 'funis/') + 'lista/' + this.$route.params.campaign_id },
        { link: "Edição de formulário", to: this.$route.fullPath },
      ],
      repeatedItems: [],
      itemOccurrences:{},
      loading: false,
      step2Dados: {},
      permission: false,
      editInput: null,
      indexEditInput: null,
      metas: [],
      form_metas: {},
      inputs: [
        {
          icon: "Vector",
          name: "Grupo de checkbox",
          html: `<li class="prev-holder"><div class="fb-checkbox-group form-group field-checkbox-group-1672681374758-preview"><label class="fb-checkbox-group-label" name="checkbox-group-preview">Grupo de Checkbox</label><span class="required-asterisk" style="display: none;"> *</span><div class="checkbox-group"><div class="checkbox"><input  name="checkbox-group-1672681374758-preview" class="" id="checkbox-group-1672681374758-preview-0" value="opo-1" type="checkbox"><label for="checkbox-group-1672681374758-preview-0">Opção 1</label></div></div></div></li>`,
          json: {
            type: "checkbox-group",
            label: "Grupo de checkbox",
            name: "checkbox-group",
            values: [
              {
                label: "Opção 1",
                value: "opo-1",
                selected: "true",
              },
            ],
          },
        },
        {
          icon: "Vector-1",
          name: "Campo de data",
          html: `<li class="prev-holder" style=""><div class="fb-date form-group field-date-1672686620043-preview"><label class="fb-date-label">Campo de Data</label><span class="required-asterisk" style="display: none;"> *</span><input  type="date" class="form-control" name="date-preview" id="date-1672686620043-preview"></div></li>`,
          json: {
            type: "date",
            label: "Campo de Data",
            className: "form-control",
            name: "date",
          },
        },
        {
          icon: "Vector-5",
          name: "Upload de arquivo",
          html: `<li class="file-field form-field" type="file" id="frmb-1672687220316-fld-7"><div class="prev-holder" style=""><div class="fb-file form-group field-file-1672687740979-preview"><label class="fb-file-label">Upload de Arquivo</label><span class="required-asterisk" style="display: none;"> *</span><input  type="file" class="form-control" name="uploadfile-preview" id="file-1672687740979-preview"></div></div></li>`,
          json: {
            type: "file",
            label: "Upload de arquivo",
            className: "form-control",
            name: "uploadfile",
          },
        },
        {
          icon: "Vector-3",
          name: "Parágrafo",
          html: `<li class="prev-holder" style=""><div class="fb-paragraph form-group field-paragraph-1672688459461-preview"><p class="" name="paragraph-preview" id="paragraph-1672688459461-preview">Parágrafo</p></div></li>`,
          json: {
            type: "paragraph",
            label: "Parágrafo",
            name: "paragraph",
          },
        },
        {
          icon: "Vector-4",
          name: "Número",
          html: `<li class="number-field form-field" type="number" id="frmb-1672687942269-fld-12"><label class="field-label">Número</label><span class="required-asterisk" style="display: none;"> *</span><div class="prev-holder"><div class="fb-number form-group field-number-1672688642716-preview"><input  type="number" placeholder="" class="form-control" name="number" id="number-1672688642716-preview"></div></div></li>`,
          json: {
            type: "number",
            label: "Número",
            className: "form-control",
            name: "number",
          },
        },
        {
          icon: "Vector-6",
          name: "Grupo de radio",
          html: `<li class="radio-group-field form-field" type="radio-group" id="frmb-1672687942269-fld-14"><div class="prev-holder"><div class="fb-radio-group form-group field-radio-group-1672689996727-preview"><label class="fb-radio-group-label" name="radio-group-preview">Grupo de 'Radio'</label><div class="radio-group"><div class="radio"><input name="radio-group-1672689996727-preview" class="" id="radio-group-1672689996727-preview-0"  value="opo-1" type="radio"><label for="radio-group-1672689996727-preview-0">Opção 1</label></div><div class="radio"><input name="radio-group-1672689996727-preview" class="" id="radio-group-1672689996727-preview-1" value="opo-2" type="radio"><label for="radio-group-1672689996727-preview-1">Opção 2</label></div><div class="radio"><input name="radio-group-1672689996727-preview" class="" id="radio-group-1672689996727-preview-2" value="opo-3" type="radio"><label for="radio-group-1672689996727-preview-2">Opção 3</label></div></div></div></div></li>`,
          json: {
            type: "radio",
            label: "Grupo de 'radio'",
            className: "form-control",
            name: "radio-group",
          },
        },
        {
          icon: "Vector",
          name: "Selecione",
          html: `<li class="select-field form-field" type="select" id="frmb-1672690689255-fld-8"><div class="prev-holder" style=""><div class="fb-select form-group field-select-1672690931717-preview"><label class="fb-select-label">Selecione</label> <span class="required-asterisk" style="display: none;"> *</span><select class="form-control" name="select" tag="0" id="select-1672690931717-preview"><option selected="true" value="opo-1" id="select-1672690931717-preview-0">Opção 1</option><option value="opo-2" id="select-1672690931717-preview-1">Opção 2</option><option value="opo-3" id="select-1672690931717-preview-2">Opção 3</option></select></div></div></li>`,
          json: {
            type: "select",
            label: "Selecione",
            className: "form-control",
            name: "select",
            tag: "0",
            values: [
              {
                label: "Opção 1",
                value: "opo-1",
                selected: "true",
              },
              {
                label: "Opção 2",
                value: "opo-2",
              },
              {
                label: "Opção 3",
                value: "opo-3",
              },
            ],
          },
        },
        {
          icon: "Vector-3",
          name: "Campo de texto",
          html: `<li class="text-field form-field" type="text" id="frmb-1672690689255-fld-9"><label class="field-label">Campo de Texto</label><span class="required-asterisk" style="display: none;"> *</span><div class="prev-holder"><div class="fb-text form-group field-text-1672691403688-preview"><input  type="text" placeholder="" class="form-control" name="text-camp-preview" mascara="form-control" id="text-1672691403688-preview"></div></div></li>`,
          json: {
            type: "text",
            label: "Campo de texto",
            className: "form-control",
            name: "text-camp",
            mascara: "form-control",
          },
        },
        {
          icon: "Vector-2",
          name: "Telefone",
          html: `<li class="text-field form-field" type="text" id="frmb-1672690689255-fld-12"><label class="field-label">Telefone</label><span class="required-asterisk" style="display: none;"> *</span><div class="prev-holder"><div class="fb-text form-group field-phone-preview"><input  type="text" placeholder="Deixe seu telefone" class="form-control" name="phone-preview" mascara="telefone form-control" id="phone-preview"></div></div></li>`,
          json: {
            type: "text",
            label: "Telefone",
            className: "form-control",
            name: "telefone",
            mascara: "form-control",
          },
        },

        {
          icon: "Vector-7",
          name: "Nome",
          html: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">Nome</label><span class="required-asterisk" style="display: none;"> *</span><input  type="text" placeholder="Seu nome" class="form-control" name="name-preview" mascara="form-control" id="nome-preview"></div></li>`,
          json: {
            type: "text",
            label: "Nome",
            className: "form-control",
            name: "nome",
            mascara: "form-control",
          },
        },
        {
          icon: "Vector-8",
          name: "Endereço de e-mail",
          html: `<li class="text-field form-field" type="text" id="frmb-1672690689255-fld-13"><label class="field-label">E-mail</label><span class="required-asterisk" style="display: none;"> *</span><div class="prev-holder" style=""><div class="fb-text form-group field-email-preview"><input  type="text" class="form-control" name="email-preview" placeholder="Deixe seu e-mail" mascara="form-control" id="email-preview"></div></div></li>`,
          json: {
            type: "text",
            label: "Email",
            placeholder: "Deixe seu e-mail",
            className: "form-control",
            name: "email",
            mascara: "form-control",
          },
        },
        {
          icon: "Vector-9",
          name: "Área de texto",
          html: `<li class="textarea-field form-field" type="textarea" id="frmb-1672690689255-fld-15"><label class="field-label">Área de Texto</label><span class="required-asterisk" style="display: none;"> *</span><div class="prev-holder"><div class="fb-textarea form-group field-textarea-1672693080469-preview"><textarea type="textarea" rows="1" placeholder="" class="form-control" name="textarea-preview" id="textarea-1672693080469-preview"></textarea></div></div></li>`,
          json: {
            type: "textarea",
            label: "Área de texto",
            className: "form-control",
            name: "text-area",
          },
        },
      ],
      forms: [
        {
          name: "Nome",
          html: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">Nome</label><span class="required-asterisk" style="display: none;"> *</span><input  type="text" placeholder="Seu nome" class="form-control" name="nome-preview" mascara="form-control" id="nome-preview"></div></li>`,
          json: {
            type: "text",
            label: "Nome",
            className: "form-control",
            name: "nome",
            mascara: "form-control",
          },
        },
        {
          name: "Endereço de e-mail",
          html: `<li class="text-field form-field" type="text" id="frmb-1672690689255-fld-13"><label class="field-label">E-mail</label><span class="required-asterisk" style="display: none;"> *</span><div class="prev-holder" style=""><div class="fb-text form-group field-email-preview"><input  type="text" class="form-control" name="email-preview" placeholder="Deixe seu e-mail" mascara="form-control" id="email-preview"></div></div></li>`,
          json: {
            type: "text",
            label: "E-mail",
            placeholder: "Deixe seu e-mail",
            className: "form-control",
            name: "email",
            mascara: "form-control",
          },
        },
        {
          name: "Telefone",
          html: `<li class="text-field form-field" type="text" id="frmb-1672690689255-fld-12"><label class="field-label">Telefone</label><span class="required-asterisk" style="display: none;"> *</span><div class="prev-holder"><div class="fb-text form-group field-phone-preview"><input  type="text" placeholder="Deixe seu telefone" class="form-control" name="phone-preview" mascara="telefone form-control" id="phone-preview"></div></div></li>`,
          json: {
            type: "text",
            label: "Telefone",
            className: "form-control",
            name: "telefone",
            mascara: "form-control",
          },
        },
      ],
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadingForm = true
      this.getForm();
    } else {
      this.isFormLoaded = true
    }
  },
  computed:{
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    validateCamps() {
      return this.inputs.filter(x => {
        return !this.inputsNames.includes(x.json.name) || (x.name !== "Telefone" && x.name !== "Endereço de e-mail" && x.name !== "Nome" )
      } );
    },
    stepStyles() {
      return this.step ? 'margin-left: -50%;' : ''
    }
  },
  methods: {
    validateNames(item){
      if(item && item.json){
        let name = item.json.name.toLowerCase();
        if(name == 'nome' || name == 'email' || name == 'telefone'){
          return false;
        }
      }
      return true;
    },
    duplicateItem(field, index) {
      const array = this.forms;
      let field_ = Object.assign({}, field);
      field_.json = Object.assign({}, field.json);

      if (field_.json && field_.json.name) {
          const originalName = field_.json.name;
          field_.json.name += "-copy";
          if (field_.html) {
              field_.html = field_.html.replace(`name="${originalName}"`, `name="${field_.json.name}"`);
          }
      }

      array.splice(index + 1, 0, field_);
      this.inputsNames.splice(index + 1, 0, field_.json.name);
      this.forms = array;
    },
    checkInputs(){
      this.itemOccurrences = {};
      this.repeatedItems = [];
      this.inputsNames.forEach(item => {
        this.itemOccurrences[item] = this.itemOccurrences[item] ? this.itemOccurrences[item] + 1 : 1;
      });
      
      for (const item in this.itemOccurrences) {
          if (this.itemOccurrences[item] > 1) {
              this.repeatedItems.push(item);
          }
      }

      if (this.repeatedItems.length > 0) {
        
        this.$bvToast.toast(
          "Identidades repetidas",
          {
            title: "Formulário",
            variant: "danger",
            autoHideDelay: 3000,
            appendToast: true,
          }
        );
        let inputName = document.querySelectorAll(`[name="${this.repeatedItems[0].toLowerCase()}"]`);
        inputName = !inputName.length ? document.querySelectorAll(`[name="${this.repeatedItems[0].toLowerCase()}-preview"]`) : inputName;

        for (let i = 0; i < inputName.length; i++) {
          const element = inputName[i];
          if(element.getAttribute('name') == 'checkbox-group-preview' || element.getAttribute('name') == 'paragraph-preview' || element.getAttribute('name') == 'radio-group-preview'){
              element.style = "color:red !important;";
          }else{
            element.style = "border:0.5px solid red !important;";
          }
        }
      } else {
          this.step = this.step+1;
      }
      return
    },
    removeItem(position) {
      this.repeatedItems = [];
      this.itemOccurrences = {};
      const array = this.forms;
      const inputIndex = this.inputsNames.findIndex(name => name === this.forms[position].json.name);
      if (inputIndex !== -1) {
        this.inputsNames.splice(inputIndex, 1);
      }      
      array.splice(position, 1);
      this.forms = array;
    },
    log: function (evt) {
      this.inputsNames.splice(evt.added.newIndex, 0,evt.added.element.json.name);
    },
    cloneInput(item) {
      const clone = JSON.parse(JSON.stringify(item))
      return {
        html: clone.html,
        json: clone.json,
        name: clone.name,
      };
    },
    getForm() {
      serviceForm
        .read({ id: this.$route.params.id })
        .then((resp) => {
          var forms = [];
          if(resp.fields.length){
            this.inputsNames = [];
          }
          resp.fields.forEach((field) => {
            this.inputsNames.push(field.json.name);
              forms.push({
              html: field.form_field,
              id: field.id,
              json: field.json,
              name: field.json.name,
            });
          });
          this.forms = forms;
          this.step2Dados = {
            title: resp.title,
            forms: forms,
            metas: resp.metas,
          };
          this.form_metas = resp;
        })
        .catch(() => {
          this.$bvToast.toast("Erro ao encontrar formulário", {
            title: "Configurações",
            variant: "danger",
            autoHideDelay: 300,
            appendToast: true,
          });
          setTimeout(() => {
            this.$router.push(`/dynamicRoute/funis/lista/${this.$route.params.campaign_id}?tab=formularios`);
          }, 2000);
        })
        .finally(() => {
          this.isFormLoaded = true
          this.loadingForm = false
          if(this.$refs.configForm){
            this.$refs.configForm.setContetForm();
          }
        });
    },
    saveData() {
      var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/g;
      this.permission = true;
      this.loading = true
      setTimeout(() => {
        this.permission = false;
        this.forms.forEach((item) => {
          this.fields += item.html;
        });
        if (
          !this.step2Dados.title ||
          !this.step2Dados.title.match(/([A-Z a-z])\w+/g)
        ) {
          this.$bvToast.toast("Preencha o título", {
            title: "Configurações",
            variant: "danger",
            autoHideDelay: 300,
            appendToast: true,
          });
          this.loading = false
          return;
        }

        if (
          this.step2Dados.title.length < 6 ||
          this.step2Dados.title.length > 40
        ) {
          this.$bvToast.toast(
            "Mínimo de 6 caracteres e máximo de 40 caracteres",
            {
              title: "Configurações",
              variant: "danger",
              autoHideDelay: 300,
              appendToast: true,
            }
          );
          this.loading = false
          return;
        }

        if (/  /g.test(this.step2Dados.title)) {
          this.$bvToast.toast("Espaços inválidos no título", {
            title: "Configurações",
            variant: "danger",
            autoHideDelay: 300,
            appendToast: true,
          });
          this.loading = false
          return;
        }
        // if (!this.step2Dados.metas.selected_page) {
        //   this.$bvToast.toast("Selecione uma página de agradecimento", {
        //     title: "Configurações",
        //     variant: "danger",
        //     autoHideDelay: 300,
        //     appendToast: true,
        //   });
        //   return;
        // }

        // if (!this.step2Dados.metas.journey_type) {
        //   this.$bvToast.toast("Selecione uma jornada", {
        //     title: "Configurações",
        //     variant: "danger",
        //     autoHideDelay: 300,
        //     appendToast: true,
        //   });
        //   return;
        // }

        if (
          this.step2Dados.metas.receive_email === "true" &&
          !this.step2Dados.metas.register_email_lead.length
        ) {
          this.$bvToast.toast("Insira pelo menos um e-mail", {
            title: "Configurações",
            variant: "danger",
            autoHideDelay: 300,
            appendToast: true,
          });
          this.loading = false
          return;
        }

        if (
          this.step2Dados.metas.add_pipeline === "true" &&
          !this.step2Dados.metas.pipeline_id
        ) {
          this.$bvToast.toast("Selecione um CRM", {
            title: "Configurações",
            variant: "danger",
            autoHideDelay: 300,
            appendToast: true,
          });
          this.loading = false
          return;
        }

        if (
          this.step2Dados.metas.add_pipeline === "true" &&
          !this.step2Dados.metas.board_id
        ) {
          this.$bvToast.toast("Selecione uma tag", {
            title: "Configurações",
            variant: "danger",
            autoHideDelay: 300,
            appendToast: true,
          });
          this.loading = false
          return;
        }

        if (
          this.step2Dados.metas.lead_distribuition === "true" &&
          !this.step2Dados.metas.users_list 
        ) {
          this.$bvToast.toast("Selecione um vendedor ao menos", {
            title: "Configurações",
            variant: "danger",
            autoHideDelay: 300,
            appendToast: true,
          });
          this.loading = false
          return;
        }

        if (this.$route.params.id) {
          this.step2Dados.id = `/${this.$route.params.id}`;
          serviceForm
            .update(this.step2Dados)
            .then((resp) => {
              this.loading = false
              this.$bvToast.toast("Formulário atualizado com sucesso", {
                title: "Configurações",
                variant: "info",
                autoHideDelay: 300,
                appendToast: true,
              });
              this.getForm();
              // this.$store.dispatch("formRequest");
              // setTimeout(() => {
              this.$router.push({
                name: "CampanhasListaUnica",
                params: { id: this.$route.params.campaign_id },
                query: { tab: "formularios" },
              });
              // }, 2000);
            })
            .catch((err) => {
              this.loading = false
              if (
                err.response.data.error === "The title has already been taken."
              ) {
                this.$bvToast.toast("Já existe um formulário com esse título", {
                  title: "Configurações",
                  variant: "danger",
                  autoHideDelay: 300,
                  appendToast: true,
                });
                return;
              }
              this.$bvToast.toast("Erro ao criar formulário", {
                title: "Configurações",
                variant: "danger",
                autoHideDelay: 300,
                appendToast: true,
              });
            });
          return;
        }
        this.step2Dados.campaign_id = this.$route.params.campaign_id;

        serviceForm
          .create(this.step2Dados)
          .then((resp) => {
            this.loading = false
            this.$bvToast.toast("Formulário criado com sucesso", {
              title: "Configurações",
              variant: "info",
              autoHideDelay: 300,
              appendToast: true,
            });
            // this.$store.dispatch("formRequest");
            // setTimeout(() => {
            this.$router.push({
              name: "CampanhasListaUnica",
              params: { id: this.$route.params.campaign_id },
              query: { tab: "formularios" },
            });
            // }, 2000);
          })
          .catch((err) => {
            this.loading = false
            if (
              err.response.data.error === "The title has already been taken."
            ) {
              this.$bvToast.toast("Já existe um formulário com esse título", {
                title: "Configurações",
                variant: "danger",
                autoHideDelay: 300,
                appendToast: true,
              });
              return;
            }
            this.$bvToast.toast("Erro ao criar formulário", {
              title: "Configurações",
              variant: "danger",
              autoHideDelay: 300,
              appendToast: true,
            });
          });
      }, 200);
    },
    puxarDados(data) {
      this.step2Dados = data;
      for (let i = 0; i < this.forms.length; i++) {
        this.forms[i].order = i;
      }
      this.step2Dados.form = this.forms;
    },
    openModal(modal, item, index) {
      this.editInput = JSON.parse(JSON.stringify(item));
      this.indexEditInput = index;
      this.$bvModal.show(modal);
    },
    updateHtml(e) {
      if (this.indexEditInput !== null) {
        var match = e.match(/name="([^"]*)-preview"/);
        this.inputsNames[this.indexEditInput] = match ? match[1] : null;
        this.forms[this.indexEditInput].json.name = match ? match[1] : null;
        Vue.set(this.forms[this.indexEditInput], "html", e);
        this.indexEditInput = null;
        setMask();
      }
    },
  },
  watch: {
    forms() {
      setMask();
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb-form {
  display: flex;
  gap: 10px;
  padding-left: 30px;
  img {
    width: 15px;
    height: 15px;
  }
  a {
    font-size: 14px;
    color: #81858e !important;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      color: #57585c !important;
    }
  }

  li {
    display: flex;
    gap: 10px;

    a {
      font-size: 14px;
      color: #81858e !important;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.3s;
      &:hover {
        color: #57585c !important;
      }
    }
  }
}
.step-slider {
  display: flex;
  width: calc(200vw - 200px);
  > div {
    transition: .5s;
    width: calc(100vw - 100px);
    max-height: calc(100vh - 89px);
    overflow-y: auto;
  }
}

.step-slider2 {
  display: flex;
  width: calc(200vw - 200px);
  > div {
    transition: .5s;
    width: calc(100vw - 150px);
    max-height: calc(100vh - 89px);
    overflow-y: auto;
  }
}
.inputs-list:hover {
  .actions {
    opacity: 1;
    z-index: 1;
  }
}
.inputs-list {
  position: relative;
  .actions {
    position: absolute;
    opacity: 0;
    gap: 10px;
    transition: .1s;
    display: flex;
    right: 0;
    top: -5px;
    button {
      background: var(--gray01);
      border: none;
      color: white;
      height: 32px;
      width: 32px;
      transition: .3s;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      &:hover {
        background-color: var(--greenn) !important;
        transition: all 0.1s;
      }
    }
  }
}
.drag-body {
  width: 100%;
  height: calc(100vh - 89px);
  max-height: calc(100vh - 89px);
  display: grid;
  grid-template-columns: 345px 1fr;
  .form-area {
    padding: 25px 35px;
    .form-maker {
      background: #f7f7f7;
      border-radius: 10px;
      width: 90%;
      position: relative;
      overflow: hidden;
      input, textarea {
        pointer-events: none;
      }
      .border-form {
        overflow-y: auto;
        padding: 82px 0 50px;
        max-height: calc(100vh - 230px);
        min-height: calc(100vh - 230px);
        display: grid;
        align-items: center;
        justify-content: center;
        > div {
          border: 1px solid #535353;
          border-radius: 10px;
          width: 400px;
          padding: 20px;
        }
      }
      .header-form {
        z-index: 2;
        background: var(--gray01);
        transform: matrix(1, 0, 0, -1, 0, 0);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 32px;
        border-radius: 0 0 5px 5px;
        padding: 12px;
        display: flex;
        gap: 7px;
        .ball {
          width: 8px;
          height: 8px;
          background: var(--red);
          border-radius: 100%;
        }
      }
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: var(--gray01);
    }
    p {
      font-weight: 400;
      font-size: 14px;
      color: #81858e;
    }
  }
  .inputs {
    width: 100%;
    height: calc(100vh - 89px);
    border-right: 1px solid #ededf0;
    padding: 25px 35px;
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.185);
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 3px;
      background: transparent;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    .input {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 18px;
      height: 54px;
      border: 1px dashed #bdbdbd;
      border-radius: 10px;
      margin-bottom: 10px;
      transition: .3s;
      cursor: grab;
      img {
        margin-right: 20px;
      }
      p {
        margin: 0;
        font-weight: 400;
        font-size: 13px;
      }
      &:hover {
        background-color: var(--greenn2);
        border-color: var(--greenn);
      }
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: var(--gray01);
      margin-bottom: 30px;
    }
  }
}
.max-form {
  position: absolute;
  top: 0;
  left: 100px;
  width: calc(100% - 100px);
  height: 100vh;
  // height: calc(107% - 100px);
  overflow: scroll;
  overflow-y: scroll;
  overflow-x: hidden;
}

.max-form::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  // border-radius: 20px;
}
.max-form::-webkit-scrollbar-track {
  background-color: transparent;
}
.max-form::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
.header {
  height: 88px;
  border-bottom: 1px solid #ededf0;
  display: flex;
  justify-content: space-between;
}

.sequence {
  align-items: center;
  display: flex;
  gap: 35px;
  padding-right: 35px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #81858e;
    margin: 0;
    cursor: pointer;
  }
  .active {
    font-weight: 600;
    color: var(--gray01);
  }
  .active::before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 6px;
    border-radius: 100%;
    background-color: var(--gray01);
    position: relative;
    left: -5px;
    top: -1px;
  }
}

.breadcrumb-form {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--gray01);
  margin-left: 35px;
  padding-top: 35px;
  span {
    color: #81858e;
    cursor: pointer;
  }
}

.disabled {
  pointer-events: none;
}
</style>
