<template>
  <BaseModal
    name="input-modal"
    id="input-modal"
    idModal="input-modal"
    size="lg"
    :title="dynamicTitle"
    @shown="openModal"
  >
    <b-row id="modal-input">
      <b-col cols="12">
        <b-form-group>
          <b-form-group
            v-if="
              editInput && editInput.json && editInput.json.label != 'Parágrafo'
            "
          >
            <label>Título do campo</label>
            <input
              class="w-100"
              type="text"
              name="titulo"
              id="titulo"
              v-model="label"
            />
          </b-form-group>
          <b-form-group
            v-if="checkLabel"
          >
            <label>Texto de ajuda</label>
            <input
              class="w-100"
              type="text"
              v-model="placeholder"
              name="texto_ajuda"
              id="texto_ajuda"
            />
          </b-form-group>
          <b-form-group
            v-if="
              editInput &&
              editInput.json &&
              editInput.json.label.toLowerCase() != 'parágrafo'
            "
          >
            <label>Nome identidade do sistema</label>
            <input
              class="w-100"
              type="text"
              name="identidade"
              id="titidentidadeulo"
              v-model="nome_sistema"
              :disabled="disabledType"
            />
          </b-form-group>
          <b-form-group
            v-if="
              editInput &&
              editInput.json &&
              editInput.json.label.toLowerCase() === 'campo de texto'
            "
          >
            <label>Máscara</label>
            <multiselect
              v-model="selectedMascara"
              class="masc"
              label="text"
              track-by="value"
              placeholder="Selecione a máscara"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :options="mascaras"
              :multiple="false"
              :searchable="false"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="true"
            >
            </multiselect>
          </b-form-group>
          <b-form-group
            v-if="
              editInput &&
              editInput.json &&
              editInput.json.label.toLowerCase() === 'parágrafo'
            "
          >
            <label>Parágrafo</label>
            <b-form-textarea
              id="textarea"
              v-model="paragrafo"
              no-resize
              style="height: 200px !important"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group
            v-if="
              editInput &&
              editInput.json &&
              editInput.json.label.toLowerCase() === 'área de texto'
            "
          >
            <label>Linhas</label>
            <input
              class="w-100"
              type="number"
              name="linhas"
              id="linhas"
              v-model="linhas"
              @input="validaLinha"
            />
          </b-form-group>

          <div
            v-if="checkLabelsActions">
            <div class="add-link-body2" >
              <label style="width:calc(100% - 24%)">Ações</label>
              <label v-if="
              editInput &&
                editInput.json &&
                editInput.json.label.toLowerCase() === 'selecione'">Tag</label>
              <router-link
                v-if="!tags.length && select_tag === true && verificUserLevel"
                :to="{ name: 'Tags' }"
                class="add-link link mb-0"
                >Deseja criar uma Tag?</router-link
              >
            </div>
            <div class="input-acoes">
              <div
                v-if="
                  editInput &&
                  editInput.json &&
                  editInput.json.label.toLowerCase() === 'grupo de checkbox'
                "
              >
                <div
                  v-for="(item, index) in checkboxs_read"
                  :key="index"
                  class="input-opt"
                >
                  <!-- <input type="checkbox" name="check" id="check"/> -->
                  <input
                    type="text"
                    name="opcao"
                    id="opcao"
                    placeholder="Rótulo"
                    v-model="item.lab"
                  />
                  <input
                    type="text"
                    name="opcao"
                    id="opcao"
                    placeholder="Valor"
                    v-model="item.val"
                  />
                  <img
                    v-if="index != 0"
                    class="delete"
                    @click="deletOpcao(index)"
                    src="@/assets/img/icons/x.svg"
                    alt="delete"
                  />
                </div>
              </div>

              <div
                v-if="
                  editInput &&
                  editInput.json &&
                  editInput.json.label.toLowerCase() === `grupo de 'radio'`
                "
              >
                <div
                  v-for="(item, index) in radios_read"
                  :key="index"
                  class="input-opt"
                >
                  <!-- <input type="radio" name="radio" id="radio" /> -->
                  <input
                    type="text"
                    name="opcao"
                    id="opcao"
                    placeholder="Rótulo"
                    v-model="item.lab"
                  />
                  <input
                    type="text"
                    name="opcao"
                    id="opcao"
                    placeholder="Valor"
                    v-model="item.val"
                  />
                  <img
                    v-if="index != 0"
                    class="delete"
                    @click="deletOpcao(index)"
                    src="@/assets/img/icons/x.svg"
                    alt="delete"
                  />
                </div>
              </div>

              <div
                v-if="checkLabelsValues"
              >
                <div
                  v-for="(item, index) in selects_read"
                  :key="index"
                  class="input-opt"
                >
                  <!-- <input type="radio" name="select" id="select" /> -->
                  <input
                    type="text"
                    name="opcao"
                    id="opcao"
                    placeholder="Rótulo"
                    v-model="item.lab"
                  />
                  
                  <input
                    type="text"
                    name="opcao"
                    id="opcao"
                    style="width: 300px;margin-right: 20px;"
                    placeholder="Valor"
                    v-model="item.val"
                    v-if="item.tag && item.tag == false && index == 0"
                  />
                  <input
                    type="text"
                    name="opcao"
                    id="opcao"
                    style="width: 300px;"
                    placeholder="Valor"
                    v-model="item.val"
                    v-else-if="item.tag == false"
                  />
                  
                  <BaseSelect
                    v-else
                    id="card-formulario"
                    :selectModel="item.val"
                    placeholder="Selecione a Tag"
                    track-by="id"
                    trackname="name"
                    specificType="tags"
                    :array="tags"
                    @select="item.val = $event"
                    class="multiselect"
                    :style="index == 0 ? { maxWidth: '48.5% !important' } : ''"
                    selectLabel=""
                    :searchable="false"
                    deselectLabel=""
                    noOptions="Nenhuma tag encontrada."
                    @callback="fetchTags($event)"
                    @change="item.val = $event"
                    :watch="true"
                  >
                  </BaseSelect>
                  <b-form-checkbox
                      v-model="item.tag"
                      name="is_main"
                      size="lg"
                      @change="clearInput(item,item.val.id)"
                      switch
                      class="switch6 mt-2 mover-lead"
                    >
                    </b-form-checkbox>
                  <img
                    v-if="index != 0"
                    class="delete"
                    @click="deletOpcao(index)"
                    src="@/assets/img/icons/x.svg"
                    alt="delete"
                  />
                </div>
              </div>
            </div>
            <div class="btn-right">
              <BaseButton @click="adicionarOpcao" variant="primary">
                Adicionar opção
              </BaseButton>
            </div>
          </div>
          <div
            v-if="validateAddOption"
            class="container-check"
          >
            <span>Obrigatório</span>
            <div class="check-item">
              <b-form-checkbox
                v-model="obrigatorio"
                name="is_main"
                size="lg"
                switch
                class="switch6 mt-2 mover-lead"
              >
              </b-form-checkbox>
            </div>
          </div>

          <div
            v-if="
              editInput &&
              editInput.json &&
              editInput.json.label.toLowerCase() === 'selecione'
            "
            class="container-check"
          >
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton v-if="!dont_save" @click="alterarHtml" variant="primary">
        Salvar
      </BaseButton>
      <BaseButton
        v-else
        v-b-tooltip.hover
        title="Coloque um nome na identidade do sistema"
        variant="black"
      >
        Salvar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "../../../node_modules/vue-multiselect";
import ListarTagService from "@/services/resources/ListarTagService";
const serviceListarTag = ListarTagService.build();
import TagService from "@/services/resources/TagService";
const serviceTags = TagService.build();
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      mascaras: [
        { value: "form-control", text: "Nenhuma" },
        { value: "cpf form-control", text: "CPF" },
      ],
      type: "",
      selectedMascara: {},
      current_mascara: "",
      mascara: "",
      obrigatorio: false,
      select_tag: false,
      current_placeholder: "",
      placeholder: "",
      label: "",
      current_label: "",
      current_nome_sistema: "",
      nome_sistema: "",
      current_paragrafo: "",
      paragrafo: "",
      linhas: "",
      checkboxs: [],
      checkboxs_read: [],
      selects_read: [],
      selects_read_val: [],
      radios: [],
      radios_read: [],
      dont_save: false,
      tags: [],
    };
  },
  computed: {
    disabledType(){
      return  this.type == 'nome' || this.type == 'email' || this.type == 'telefone'
    },
    validateAddOption(){
      if (this.editInput && this.editInput.json) {
        let lowercaseLabel = this.editInput.json.label.toLowerCase();
        return lowercaseLabel !== 'parágrafo';
      }
      return false;
    },
    checkLabelsValues() {
      if (this.editInput && this.editInput.json) {
        let lowercaseLabel = this.editInput.json.label.toLowerCase();
        return lowercaseLabel === 'selecione' ||
              lowercaseLabel === 'select';
      }
      return false;
    },
    checkLabelsActions(){
      if (this.editInput && this.editInput.json) {
      let lowercaseLabel = this.editInput.json.label.toLowerCase();
        return lowercaseLabel === 'grupo de checkbox' ||
              lowercaseLabel === `grupo de 'radio'` ||
              lowercaseLabel === 'selecione' ||
              lowercaseLabel === 'select';
        }
      return false;
    },
    checkLabel() {
      let labels = ['nome', 'telefone', 'número', 'campo de texto', 'área de texto', 'selecione', 'select', 'e-mail'];
      if (this.editInput && this.editInput.json) {
        let lowercaseLabel = this.editInput.json.label.toLowerCase();
        return labels.includes(lowercaseLabel);
      }
      return false;
    },
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    dynamicTitle() {
      var title = "Editar campo de ";
      if (this.editInput && this.editInput.json) {
        if (this.editInput.json.type === "checkbox-group") {
          return title + "checkbox";
        } else if (this.editInput.json.type === "date") {
          return title + "data";
        } else if (this.editInput.json.type === "file") {
          return title + "arquivo";
        } else if (this.editInput.json.type === "paragraph") {
          return title + "parágrafo";
        } else if (this.editInput.json.type === "number") {
          return title + "número";
        } else if (this.editInput.json.type === "radio") {
          return title + `grupo de 'radio'`;
        } else if (this.editInput.json.type === "select") {
          return title + "selecionar";
        } else if (this.editInput.json.type === "text") {
          return title + "texto";
        } else if (this.editInput.json.type === "textarea") {
          return title + "texto";
        }
      }

      return "Editar campo";
    },
    forms() {
      return this.$store.getters.forms;
    },
  },
  props: ["editInput"],
  methods: {
    async fetchTags(page = 1) {
      if (this.stop) {
        return;
      }

      var data = {
        page: page,
      };
      await serviceListarTag
        .search(data)
        .then((response) => {
          if (response.data.length < response.per_page) {
            this.stop = true;
          }
          this.tags = this.tags.concat(response.data);
          this.tags = this.tags.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => console.error(error));
    },
    clearInput(tag,id){
      if(tag && !tag.tag && typeof tag.val === 'object'){
        tag.val = '';
      }
      if(!tag.tag && typeof tag.val === 'object'){
        tag.val = `tag:${id}`;
      }
    },
    async openModal() {
      this.radios_read = [];
      this.checkboxs_read = [];
      this.selects_read = [];
      this.selects_read_val = [];
      this.select_tag = false;
      this.clear();
      this.type = this.editInput.json.name.toLowerCase();
      if (this.editInput !== null) {
        var html = this.editInput.html;
        if (html.match(/placeholder\=\"([^"]*)\"/)) {
          this.current_placeholder = html.match(
            /placeholder\=\"([^"]*)\"/
          )[1];
            this.placeholder = html.match(
            /placeholder\=\"([^"]*)\"/
          )[1];
        }
        let exchangeQuotes = this.placeholder.match(/&quot;/)
        if(exchangeQuotes){
          this.placeholder = this.placeholder.replace(/&quot;/g, '"')
        }

        if (html.match(/name\=\"([A-Za-z0-9 _-]*)\"/)) {
          this.current_nome_sistema = html.match(
            /name\=\"([A-Za-z0-9 _-]*)\"/
          )[1];
          var sistema = this.removeAccents(
            this.current_nome_sistema.split("-preview")[0]
          );
          if(this.editInput && this.editInput.json && this.editInput.json.name){
            sistema = this.editInput.json.name;
          }
          this.nome_sistema = sistema;
        }

        if (html.match(/rows\=\"([A-Za-z0-9 _-]*)\"/)) {
          this.linhas = html.match(/rows\=\"([A-Za-z0-9 _-]*)\"/)[1];
        }

        if (html.match(/mascara\=\"([A-Za-z0-9 _-]*)\"/)) {
          this.current_mascara = html.match(
            /mascara\=\"([A-Za-z0-9 _-]*)\"/
          )[1];
          this.mascara = html.match(/mascara\=\"([A-Za-z0-9 _-]*)\"/)[1];
          if (this.current_mascara == "form-control") {
            this.selectedMascara = { value: "form-control", text: "Nenhuma" };
          } else if (this.current_mascara == "cpf form-control") {
            this.selectedMascara = { value: "cpf form-control", text: "CPF" };
          } else if (this.current_mascara == "telefone form-control") {
            this.selectedMascara = {
              value: "telefone form-control",
              text: "Telefone",
            };
          } else {
            this.selectedMascara = {
              value: "celular form-control",
              text: "Celular",
            };
          }
        }

        if (html.match(/<label class="([^<]*)">([^<]*)<\/label>/)) {
          this.current_label = html.match(
            /<label class="([^<]*)">([^<]*)<\/label>/
          )[2];

          this.label = html.match(/<label class="([^<]*)">([^<]*)<\/label>/)[2];
        }

        if (
          html.match(/<label for="([^<]*)"  class="([^<]*)">([^<]*)<\/label>/)
        ) {
          this.label = html.match(
            /<label for="([^<]*)"  class="([^<]*)">([^<]*)<\/label>/
          )[3];
        }

        if (html.match(/<p class="([^<]*)">([^<]*)<\/p>/)) {
          this.paragrafo = html.match(/<p class="([^<]*)">([^<]*)<\/p>/)[2];
          this.current_paragrafo = html.match(
            /<p class="([^<]*)">([^<]*)<\/p>/
          )[2];
        }

        if (html.match(/<div(?:[^>]+class=\"(radio.*)\"[^>]*)?>(.*?)<\/div>/)) {
          var radios = html.match(
            /<div(?:[^>]+class=\"(radio.*)\"[^>]*)?>(.*?)<\/div>/
          )[0];
          this.radios = this.removeLast(radios);
          for (let i = 0; i < this.radios.length; i++) {
            var whitout_for = this.radios[i].replace(
              /for\=\"([A-Za-z0-9 _-]*")/
            );
            var label = whitout_for.match(
              /<label(?:[^>]+[^>]*)?>(.*?)<\/label>/
              );
            if (label && label[1]) {
              var lab = label[1];
              // this.radios_lab[i] = lab;
              let val = whitout_for.match(/value="([^"]+)"/)[1];
              var value = val.replace('" type="radio', "");
              this.radios_read.push({ val: value, lab: lab });
            }
          }
        }

        if (
          html.match(/<div(?:[^>]+class=\"(checkbox.*)\"[^>]*)?>(.*?)<\/div>/)
        ) {
          var checkboxs = html.match(
            /<div(?:[^>]+class=\"(checkbox.*)\"[^>]*)?>(.*?)<\/div>/
          )[0];
          this.checkboxs = this.removeLast(checkboxs);
          for (let i = 0; i < this.checkboxs.length; i++) {
            setTimeout(() => {
              var whitout_for = this.checkboxs[i].replace(
                /for\=\"([A-Za-z0-9 _-]*")/,
                ""
                );
                var label = whitout_for.match(
                  /<label(?:[^>]+[^>]*)?>(.*?)<\/label>/
                  );
                  var lab = label[1];
                  var val = whitout_for.match(/value="([^<]*)"/)[1];
                  var value = val.replace('" type="checkbox', "");
              this.checkboxs_read.push({ val: value, lab: lab });
            }, 200);
          }
        }

        if (html.match(/<option(?:[^>]+"[^>]*)?>(.*?)<\/option>/)) {
          var options = html.match(/<option(?:[^>]+"[^>]*)?>(.*?)<\/option>/g);
          for (let i = 0; i < options.length; i++) {
            const element = options[i];
            var val = element.match(/value="([^<]*)" /)[1];
            var lab = element.match(
              /<option(?:[^>]+[^>]*)?>(.*?)<\/option>/
            )[1];

            if (val !== `" disabled="null" selected="null`) {
              if(typeof val == 'string' && val.match(/tag:/)){
                let tag_ = await this.getTag(val);
                this.selects_read.push({ val: tag_, lab: lab , tag:true});
              }else if(typeof val === 'object'){
                this.selects_read.push({ val: val, lab: lab , tag:false});
              }else{
                this.selects_read.push({ val: val, lab: lab , tag:false});
              }
            } else {
              this.placeholder = lab;
            }
          }
        }
        if (html.match(/required=\"([A-Za-z0-9 _-]*)\"/)) {
          this.obrigatorio = true;
        }else{
          this.obrigatorio = false;
        }
      }
    },
    getTag(val) {
      return new Promise((resolve) => {
        let tag = this.tags.find((x) => x.id == val.split(":")[1]);
        if (!tag) {
          serviceTags
          .read(`/show/${element}`)
          .then((resp) => {
            tag = resp
          })
        }
        resolve(tag);
      });
    },
    clear() {
      this.placeholder = "";
      this.current_placeholder = "";
      this.nome_sistema = "";
      this.current_nome_sistema = "";
      this.label = "";
      this.current_label = "";
      this.select_tag = false;
    },
    removeAccents(str) {
      // Converte para caracteres comuns
      str = str
        .toLowerCase()
        .replace(/[áàãâä]/gi, "a")
        .replace(/[éèêë]/gi, "e")
        .replace(/[íìîï]/gi, "i")
        .replace(/[óòõôö]/gi, "o")
        .replace(/[úùûü]/gi, "u")
        .replace(/[ç]/gi, "c")
        .replace(/[ñ]/gi, "n")
        .replace(/\s+/g, "-");
      // Remove caracteres especiais
      str = str.replace(/[^a-z0-9]/gi, "-");
      str = str.replace(/-+/gi, "-");
      str = str.replace(/  ?/gi, "-");
      return str;
    },
    adicionarOpcao() {
      if (
        this.editInput.json &&
        this.editInput.json.label.toLowerCase() === "grupo de checkbox"
      ) {
        this.checkboxs_read.push({ lab: "", val: "" });
      } else if (
        this.editInput.json &&
        this.editInput.json.label.toLowerCase() === `grupo de 'radio'`
      ) {
        this.radios_read.push({ lab: "", val: "" });
      } else if (
        (this.editInput.json &&
          this.editInput.json.label.toLowerCase() === "selecione") ||
        (this.editInput.json &&
          this.editInput.json.label.toLowerCase() === "select")
      ) {
        this.selects_read.push({ lab: "", val: "" ,tag: true });
      }
    },
    deletOpcao(index) {
      if (
        this.editInput.json &&
        this.editInput.json.label.toLowerCase() === "grupo de checkbox"
      ) {
        this.checkboxs_read.splice(index, 1);
      } else if (
        this.editInput.json &&
        this.editInput.json.label.toLowerCase() === `grupo de 'radio'`
      ) {
        this.radios_read.splice(index, 1);
      } else if (
        (this.editInput.json &&
          this.editInput.json.label.toLowerCase() === "selecione") ||
        (this.editInput.json &&
          this.editInput.json.label.toLowerCase() === "select")
      ) {
        this.selects_read.splice(index, 1);
      }
    },
    alterarHtml() {
      var html = this.editInput.html;
      if (
        this.editInput.json &&
        this.editInput.json.label.toLowerCase() == "parágrafo"
      ) {
        let new_html = html.replace(this.current_paragrafo, this.paragrafo);
        this.$emit("updateHtml", new_html);
        this.$bvModal.hide("input-modal");
        return;
      }
      let exchangeQuotes = this.placeholder.match(/\"/)
      if(exchangeQuotes){
        this.placeholder = this.placeholder.replace(/"/g, '&quot;')
      }

      html = html.replace(
        /mascara\=\"([A-Za-z0-9 _-]*)\"/,
        `mascara="${this.selectedMascara.value}"`
      );
      if( !this.disabledType && (this.nome_sistema == 'nome' || this.nome_sistema == 'email' || this.nome_sistema == 'telefone')){
        this.$bvToast.toast("Identidade Invalida!", {
          title: "Edição",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        return;
      }
      
      html = html.replace(
        `id="${this.current_nome_sistema}"`,
        `id="${this.nome_sistema}-preview"`
      );

      if (!this.obrigatorio) {
        html = html.replace(
          /placeholder\=\"([^"]*)"/,
          `placeholder="${this.placeholder}"`
        );
        html = html.replace(/aria-required\=\"([A-Za-zÀ-ÖØ-öø-ÿ _-]*)\"/, "");
        html = html.replace(/required\=\"([A-Za-zÀ-ÖØ-öø-ÿ _-]*)\"/, "");
      }

      html = html.replace(
        /placeholder\=\"([^"]*)"/,
        `placeholder="${this.placeholder}"`
      );

      if (
        this.editInput.json.label.toLowerCase() == "nome" ||
        this.editInput.json.label.toLowerCase() == "telefone" ||
        this.editInput.json.label.toLowerCase() == "campo de texto" ||
        this.editInput.json.label.toLowerCase() == "área de texto" ||
        this.editInput.json.label.toLowerCase() == "endereço de e-mail"
      ) {
        html = html.replace(
          /<label class="([^<]*)">*([^<]*)<\/label>/,
          `<label class="field-label">${this.label}<\/label>`
        );

        html = html.replace(
          `name="${this.current_nome_sistema}"`,
          `name="${this.nome_sistema}-preview"`
        );
        if (
          this.obrigatorio &&
          !html.match(/aria-required\=\"([A-Za-zÀ-ÖØ-öø-ÿ _-]*)\"/)
        ) {
          html = html.replace(
            /placeholder\=\"([^"]*)\"/,
            `placeholder="${this.placeholder}" required="required" aria-required="true"`
          );
        }
        if (html.match(/rows\=\"([A-Za-z0-9 _-]*)\"/)) {
          html = html.replace(
            /rows\=\"([A-Za-z0-9 _-]*)\"/,
            `rows="${this.linhas}"`
          );
        }
        this.$emit("updateHtml", html);
        this.$bvModal.hide("input-modal");
        return;
      }
      html = html.replace(
        /name\=\"([A-Za-z0-9 _-]*)\"/,
        `name="${this.nome_sistema}-preview" ${
          this.obrigatorio &&
          !html.match(/aria-required\=\"([A-Za-zÀ-ÖØ-öø-ÿ _-]*)\"/)
            ? 'required="required" aria-required="true"'
            : ""
        }`
      );
      html = html.replace(this.current_label, this.label);
      if (
        this.editInput.json &&
        this.editInput.json.label.toLowerCase() === `grupo de 'radio'`
      ) {
        var html_selects = "";
        for (let i = 0; i < this.radios_read.length; i++) {
          var radio = this.radios_read[i];
          var html_select = `<div class="radio"><input name="${this.nome_sistema}-preview" class="" id="${this.nome_sistema}-1672689996727-preview-${i}" value="${radio.val}" type="radio"><label for="${this.nome_sistema}-1672689996727-preview-${i}">${radio.lab}</label></div>`;
          html_selects += html_select;
        }

        html = `<li class="radio-group-field form-field" type="radio-group" id="frmb-1672917479364-fld-5"><label class="field-label">${this.label}</label><span class="required-asterisk" style="display: none;"> *</span><div class="prev-holder" style=""><div class="fb-radio-group form-group field-${this.nome_sistema}-preview"><div class="radio-group" ${this.obrigatorio ? 'aria-required="true" required="required"' : ""}>${html_selects}</div></div></div></li>`;
      }
      if (
        this.editInput.json &&
        this.editInput.json.label.toLowerCase() === "grupo de checkbox"
      ) {
        var html_selects = "";
        var close_div = "</div>";

        for (let i = 0; i < this.checkboxs_read.length; i++) {
          var checkbox = this.checkboxs_read[i];
          var html_select = `<div class="checkbox"><input name="checkbox-${
            this.nome_sistema ?? "checkbox-group"
          }-${i + 1}" class="" id="checkbox-group-1672681374758-preview-0" value="${
            checkbox.val
          }" type="checkbox"><label for="checkbox-group-1672681374758-preview-0">${
            checkbox.lab
          }</label></div>`;
          html_selects += html_select;
        }

        close_div += close_div;

        html = `<li class="prev-holder"><div class="fb-checkbox-group form-group field-checkbox-group-1672681374758-preview"><label class="fb-checkbox-group-label">${
          this.label
        }</label><span class="required-asterisk" style="display: none;"> *</span><div name="${
          this.nome_sistema ?? "checkbox-group"
        }-preview" class="checkbox-group" ${this.obrigatorio ? 'aria-required="true" required="required"' : ""} >${html_selects}${close_div}</li>`;
      }

      if (
        (this.editInput.json &&
          this.editInput.json.label.toLowerCase() === "selecione") ||
        (this.editInput.json &&
          this.editInput.json.label.toLowerCase() === "select")
      ) {
        var html_selects = "";
        for (let i = 0; i < this.selects_read.length; i++) {
          var select = this.selects_read[i];
          var html_select = `<option  value="${typeof select.val === 'object' ? `tag:${select.val.id}` : select.val}" id="${this.nome_sistema}-0">${select.lab}</option>`;
          html_selects += html_select;
          var label = `<option  value="" disabled="null" selected="null" id="${this.nome_sistema}-0">${this.placeholder}</option>`;
        }
        html = `<li class="select-field form-field" type="select" id="frmb-1672690689255-fld-8"><div class="prev-holder" style=""><div class="fb-select form-group field-${
          this.nome_sistema
        }-preview"><label for="${this.nome_sistema}"  class="fb-select-label">${
          this.label
        }</label> <span class="required-asterisk" style="display: none;"> *</span><select class="form-control" name="${
          this.nome_sistema
        }-preview" ${
          this.obrigatorio ? 'aria-required="true"' : ""
        }   tag="0" id="select-${this.nome_sistema}-preview">${
          this.placeholder.length ? label : ""
        }${html_selects}</select></div></div></li>`;
      }

      this.$emit("updateHtml", html);
      this.$bvModal.hide("input-modal");
    },
    validaLinha() {
      if (this.linhas < 1) {
        this.$bvToast.toast("No mínimo 1 Linha", {
          title: "Criação",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        this.linhas = 1;
      }
    },
    removeLast(array) {
      var split = array.split("</div>");
      split.pop();
      return split;
    },
    insertTag(item, event) {
      item.val = `tag:${event.id}`;
    },
    changeTag(item, event) {
      item.val = `tag:${event.id}`;
    },
  },
  watch: {
    nome_sistema(e) {
      this.nome_sistema = this.nome_sistema.length ? this.removeAccents(e) : "";

      if (this.nome_sistema === "") {
        this.dont_save = true;
        return;
      }
      this.dont_save = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.check-item {
  position: absolute !important;
  bottom: 2px !important;
  left: 98px;
}
.select-tag {
  left: 152px;
}
.container-check {
  position: relative;
  display: flex;
  margin-top: 20px;
}
span {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: var(--gray01);
}
.multiselect--active {
  z-index: 10;
}

.input-acoes {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}
.input-opt {
  display: flex;
  gap: 10px;
}
.btn-right {
  display: flex;
  justify-content: flex-end;
}
.delete {
  cursor: pointer;
  margin-bottom: 15px;
}
</style>
<style lang="scss">
.masc {
 .multiselect__tags{
  display: flex !important;
  align-items: center !important;
  .multiselect__single{
    top: 0px !important;
  }
 }
}

</style>