<template>
  <div id="sidebar-config-form">
    <b-sidebar
      id="sidebar-config"
      @shown="setaDados"
      right
      shadow
      backdrop
      ref="sidebar-config"
    >
      <div class="p-4 sidebar-body">
        <label>Incrementar temperatura</label>
        <input
          class="w-100 mb-0"
          @input="validarTemperatura"
          type="number"
          name="temp"
          id="temp"
          v-model="temperatura"
        />
        <div class="label-flex">
          <label>Adicionar tag</label>
          <div class="agradecimento-icons pointer" @click="openModal('add-tag', 'add')">
            <span>Criar tag</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
              <path d="M7.72005 1.40706V12.5929M2 7H13.4401" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <BaseSelect
          class="limitSpanconteudo"
          :selectModel="selectedTagAd"
          placeholder="Selecione uma tag"
          track-by="id"
          trackname="name"
          watch
          specificType="tags"
          :array="tags"
          :multiple="true"
          selectLabel=""
          deselectLabel="✕"
          noResult="Oops! Nenhuma Tag encontrada"
          noOptions="Pesquise por uma Tag"
          selectedLabel="✕"
          @search="debounceTags"
          :loading="loadingTags"
          :watch="true"
          @callback="fetchTags($event)"
          @change="selectedTagAda($event)"
        >
        </BaseSelect>
        <div class="label-flex">
          <label>Remover tag</label>
          <div class="agradecimento-icons pointer" @click="openModal('add-tag', 'remove')">
            <span>Criar tag</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
              <path d="M7.72005 1.40706V12.5929M2 7H13.4401" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>


        <BaseSelect
          :watch="true"
          class="limitSpanconteudo"
          :selectModel="selectedTagRemove"
          placeholder="Selecione uma tag"
          track-by="id"
          id="tag"
          trackname="name"
          specificType="tags"
          :array="tags"
          :multiple="true"
          selectLabel=""
          deselectLabel="✕"
          noResult="Oops! Nenhuma Tag encontrada"
          noOptions="Pesquise por uma Tag"
          selectedLabel="✕"
          @search="debounceTags"
          :loading="loadingTags"
          @callback="fetchTags($event)"
          @change="selectedTagsIdsRemove($event)"
        >
        </BaseSelect>
        <div class="card">
          <span class="title"> Enviar parâmetros </span>
          <p>
            Ao ativar essa opção, todos os parâmetros que o formulário receber,
            serão enviados para a página de agradecimento via GET
          </p>
          <b-form-checkbox
            v-model="parametros"
            name="is_main"
            size="lg"
            switch
            class="switch6"
          >
          </b-form-checkbox>
        </div>
        <div class="card">
          <span class="title"> Callback </span>
          <p>
            A URL de callback é utilizada para enviar os dados de inscrição do
            usuário para uma API particular, por exemplo, um sistema de vendas,
            ou um sistema externo ao nosso serviço. Ao utilizar esta opção, sua
            URL de callback receberá um POST os seguintes parâmetros:
          </p>
          <pre style="color: var(--bluee);">{{ exemplo }}</pre>
          <label>URL de destino</label>
          <input class="mb-0" type="text" name="url" id="url" v-model="urlDestino" />
        </div>
        <!-- <label>Sequência de e-mails</label>
        <multiselect
          v-model="selected_sequencia"
          id="tag"
          label="text"
          track-by="value"
          placeholder="Selecione uma sequência de e-mail"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          open-direction="top"
          :options="sequencias"
          :multiple="false"
          :taggable="false"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :show-no-results="true"
          :hide-selected="true"
        >
          <span slot="noOptions">Pesquise por uma Tag</span>
          <span slot="noResult">Oops! Nenhuma Tag encontrada</span>
        </multiselect> -->
        <!-- <div class="container-check">
          <span>Mover lead</span>
          <div
            v-b-tooltip.hover
            title="Desativar o lead em outras campanhas"
            class="check-item"
          >
            <b-form-checkbox
              v-model="mover_lead"
              name="is_main"
              size="lg"
              switch
              class="switch6 mt-2 mover-lead"
            >
            </b-form-checkbox>
          </div>
        </div> -->
        <!-- <div class="card">
          <span class="title"> ActiveCampaign </span>
          <p>Cadastrar o lead no ActiveCampaign</p>
          <b-form-checkbox
            v-model="campaign"
            name="is_main"
            size="lg"
            switch
            class="switch6 mt-2 mover-lead"
          >
          </b-form-checkbox>
        </div> -->
      </div>
    </b-sidebar>
    <AddTag @addTagToArray="addTagToArray" />
  </div>
</template>

<script>
import Multiselect from "../../../node_modules/vue-multiselect";
import BaseButton from "@/components/BaseButton";
import AddTag from "@/components/Tags/AddTag";
//
import ListarTagService from "@/services/resources/ListarTagService";
const serviceListarTag = ListarTagService.build();
//
import _ from "lodash";
//
export default {
  data() {
    return {
      newTagType: 'add',
      pageTags: 1,
      searchTags: "",
      loadingTags: false,
      stopTags: false,
      stop: false,
      temperatura: "",
      selectedTagAd: [],
      selectedTagRemove: [],
      parametros: false,
      urlDestino: "",
      limitSet: 0,
      exemplo: `array(
  'name' => 'Antonio da Silva',
  'email' => 'antonio@servidor.com',
  'phone' => '(00)00000-0000',
)`,
      sequencias: [
        { value: "ddd", text: "ddd" },
        { value: "dedede", text: "dedede" },
        { value: "dede", text: "dede" },
        { value: "", text: "Teste Multi Tag" },
      ],
      campaign: false,
      mover_lead: false,
      tags: [],
    };
  },
  props: ["permission_sidebar", "dados", "form"],
  components: {
    Multiselect,
    BaseButton,
    AddTag
  },
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    // tags() {
    //   return this.$store.getters.tags;
    // },
  },
  methods: {
    debounceTags: _.debounce(function (query) {
      if (query === "") {
        this.loadingTags = false;
      } else {
        this.searchTags = query;
        this.fetchTags(this.pageTags, query);
      }
    }, 500),
    selectedTagAda(event){
      let tagIsRemove
      if(this.selectedTagRemove){
        tagIsRemove = this.selectedTagRemove.find((tag)=>{
          if(tag && event.length){
            return tag.id == event[event.length -1].id
          }
        })
      }
      if(!tagIsRemove){
        this.selectedTagAd = event.filter(item => item.id !== 0 && item.id !== undefined)
      }else{
        event.pop()
        this.callMensage(true)
      }
      
    },
    selectedTagsIdsRemove(event){
      let tagIsAdd;
      if(this.selectedTagAd){
        tagIsAdd = this.selectedTagAd.find((tag)=>{
          if(tag && event.length){
            return tag.id == event[`${event.length -1}`].id 
          }
        })
      }
      if(!tagIsAdd){
        this.selectedTagRemove = event.filter(item => item.id !== 0 && item.id !== undefined)
      }else{
        event.pop()
        this.callMensage(false)
      }
    },
    callMensage(call){
      let is = call ? '(Remover)' : '(Adicionar)' 
      this.$bvToast.toast(`A tag já se encontra em Tag ${is}`, {
          title: "Formulário",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
    },
    fetchTags(page = 1, query = "") {
      if (this.stopTags || this.loadingTags) {
        return;
      }

      this.loadingTags = true;

      serviceListarTag
        .read(`?page=${page}&order_by=name&order=ASC&search=${query}`)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopTags = true;
          }
          this.tags = this.tags.concat(resp.data);
          this.tags = this.tags.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingTags = false;
        });
    },
    validarTemperatura() {
      if (this.temperatura < 0) {
        this.$bvToast.toast("A temperatura não pode ter valor negativo", {
          title: "Configurações",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        this.temperatura = 0;
      }
    },
    openModal(modal, type) {
      this.$bvModal.show(modal);
      this.newTagType = type
    },
    addTagToArray(tag) {
      this.tags.unshift(tag)
      if (this.newTagType == 'add') {
        this.selectedTagAd.push(tag)
      } else {
        this.selectedTagRemove.push(tag)
      }
    },
    getMeta(metas, chave) {
      if (!metas || !chave) {
        return;
      }
      var result = metas.find((x) => x.meta_key == chave);
      if (!result) {
        return "";
      }
      return result.meta_value;
    },
    async setaDados() {
      if (this.dados && this.limitSet == 0) {              
        this.limitSet = 1;                                                                                                                                                             
        this.temperatura = this.getMeta(this.dados.metas, "leadscore");
        this.selectedTagAd = this.dados.tags_subs;
        this.selectedTagRemove = this.dados.tags_unsubs;
        this.parametros =
          this.getMeta(this.dados.metas, "get_params") === "true"
            ? true
            : false;
        this.urlDestino = this.getMeta(this.dados.metas, "url_callback");
        this.mover_lead =
          this.getMeta(this.dados.metas, "move_lead") === "true" ? true : false;

        this.campaign =
          this.getMeta(this.dados.metas, "campaign") === "true" ? true : false;
      }
    },
  },
  created() {
    this.setaDados();
  },
  watch: {
    permission_sidebar(e) {
      if (e) {
        let data = {
          leadscore: this.temperatura,
          tag_subs: this.selectedTagAd && this.selectedTagAd.length ? `${this.selectedTagAd.map(item => item.id)}` : "",
        
          tag_unsubs: this.selectedTagRemove && this.selectedTagRemove.length ? `${this.selectedTagRemove.map(item => item.id)}` : "",  
          get_params: this.parametros ? "true" : "false",
          url_callback: this.urlDestino,
          campaign: this.campaign ? "true" : "false",
          move_lead: this.mover_lead ? "true" : "false",
        };
        this.$emit("pegarDados", data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.check-item {
  position: absolute !important;
  bottom: 2px !important;
  left: 98px !important;
}
.container-check {
  position: relative;
  display: flex;
  margin-top: 20px;
}
.b-sidebar-header {
  padding: 20px !important;
}
.sidebar-body {
  label {
    font-size: 14px !important;
    margin: 15px 0;
  }
}

.switch6 {
  z-index: 0 !important;
}
.label-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  path {
    stroke: var(--greenn)
  }
}
.agradecimento-icons {
  margin-top: 3px;
  user-select: none;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 5px 8px;
  border: 1px solid var(--greenn);
  border-radius: 10px;
  transition: .3s;
  cursor: pointer;
  span {
    font-size: 12px;
    color: var(--greenn);
    line-height: 1;
    color: var(--greenn);
    font-weight: 500;
  }
  svg > path {
    stroke: var(--greenn);
  }
  &:hover {
    background-color: var(--greenn2)
  }
}
.btn-automacao {
  margin: 15px auto;
  width: 100%;
}
#sidebar-config {
  background: white !important;
}
.more {
  margin-top: 8px;
  cursor: pointer;
}
.card {
  max-width: 400px;
  background: var(--greenn2);
  border: 1px solid var(--greenn);
  border-radius: 10px;
  margin: 20px auto;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .title {
    font-size: 14px;
    color: var(--greenn);
    font-weight: 600;
  }
  p {
    font-size: 13px;
    line-height: 1.5;
  }
}
.multiselect--active {
  z-index: 10;
}
</style>
<style>
.limitSpanconteudo .multiselect__option span:not(.select-tag-dot){
  overflow: hidden;
  display: block;
  width: 300px !important;
  text-overflow: ellipsis;
}
</style>